import s from "./NavBar.module.css";

function NavBar(p) {
	return (
		<div className={s.navBar}>
			<div
				onClick={() => p.onClick(0)}
				className={p.active === 0 ? s.aTab : s.tab}
			>
				Home
			</div>
			<div
				onClick={() => p.onClick(1)}
				className={p.active === 1 ? s.aTab : s.tab}
			>
				What's new?
			</div>
			<div
				onClick={() => p.onClick(2)}
				className={p.active === 2 ? s.aTab : s.tab}
			>
				Help
			</div>
		</div>
	);
}

export default NavBar;
