import s from "./WhatsNew.module.css";

function WhatsNew(p) {
	return (
		<div className={s.whatsNew}>
			<div className={s.text}>
				<p>
					<div className={s.title}>What's new in version 1.3.0?</div>
					<ul>
						<li>Improved the encoding and decoding algorithm.</li>
						<li>
							Increased the raw bits per second from 750 to 1200.
						</li>
						<li>
							Increased the maximum number of bytes that can be
							sent from 200 to 1024.
						</li>
						<li>
							New icons show if errors are detected or messages
							fail to decrypt.
						</li>
						<li>New icons show if messages have no errors.</li>
						<li>New confirmation icons.</li>
						<li>Shows the level of the microphone volume.</li>
						<li>Shows the number of new unseen messages.</li>
						<li>
							Shows how many bytes need to be removed if the
							message is too long.
						</li>
						<li>Shows how many bits were corrected.</li>
						<li>Shows a preview of the last sent message.</li>
						<li>
							Shows a countdown timer after sending a message.
						</li>
						<li>Messages now start decoding faster.</li>
						<li>GPS of the phone can be sent.</li>
						<li>Phone vibrates when a message is received.</li>
						<li>Added commands.</li>
						<li>Added error correction.</li>
						<li>The squelch level can be changed.</li>
						<li>
							Raw data does not show the bit error rate anymore.
						</li>
						<li>UI changes.</li>
						<li>Other small changes.</li>
					</ul>
					<br />
					<div className={s.title}>Changes in version 1.2.1</div>
					<ul>
						{/* <li> Supports end to end encryption with AES-256.</li> */}
						<li>Longer messages can be sent.</li>
						<li>Other small changes.</li>
					</ul>
					<br />
					<div className={s.title}>Changes in version 1.2.0</div>
					<ul>
						<li>Improved the reliability of sending messages.</li>
						<li>
							Shows if the app is transmitting, receiving or
							listening.
						</li>
						<li>
							Icons now show if a message was delivered or not.
						</li>
						<li>
							Shows a confirmation pop up menu when deleting
							messages.
						</li>
						<li>A few other changes.</li>
						<li>Some fixes.</li>
					</ul>
					<br />
					<div className={s.title}>Changes in version 1.1.0</div>
					<ul>
						<li>Better encoding and decoding algorithm.</li>
						<li>
							Sending messages and confirmations are more
							reliable.
						</li>
						<li>Length of the bit clock can be changed.</li>
						<li>Fixed some issues that caused the app to crash.</li>
						<li>Phone stays turned on while on the app.</li>
						<li>Few other small changes.</li>
					</ul>
					<br />
					The app can only send messages between the same version.
				</p>
			</div>
		</div>
	);
}

export default WhatsNew;
