import { useEffect, useState } from "react";
import s from "./App.module.css";
import MainPage from "./Pages/MainPage";
import NavBar from "./components/NavBar";
import WhatsNew from "./Pages/WhatsNew";
import Help from "./Pages/Help";

function onPhone() {
	let width = window.innerWidth;
	let height = window.innerHeight;

	if (width < height) {
		return true;
	}

	return false;
}

function App() {
	const [phone, setPhone] = useState(false);
	const [page, setPage] = useState(0);

	const pages = [
		<MainPage phone={phone} />,
		<WhatsNew phone={phone} />,
		<Help phone={phone} />,
	];

	useEffect(() => {
		setPhone(onPhone());
	}, []);

	window.addEventListener("resize", (e) => {
		setPhone(onPhone());
	});

	return (
		<div className={s.App}>
			<NavBar
				onClick={(i) => {
					setPage(i);
				}}
				active={page}
			/>
			{pages[page]}
		</div>
	);
}

export default App;
