import s from "./MainPage.module.css";

function MainPage(p) {
	return (
		<div>
			<div className={s.banner}>
				<div className={s.bannertitle}>Radiowave</div>
				<div className={s.bannertext}>
					An easy to use texting app, designed to send messages over
					radio waves.
				</div>
			</div>

			<div className={p.phone ? s.phonecolumn : s.column}>
				<div className={p.phone ? s.phonecontainer : s.container}>
					<div className={s.text}>
						<div className={s.title}>What can radiowave do?</div>
						<ul>
							<li>
								Send up to a kilobyte of text in one message
							</li>
							<li>
								Confirm messages have been delivered
								successfully
							</li>
							<li>Raw speed of 1200 bits per second</li>
							<li>Send GPS location of the phone</li>
							<li>Detect and correct errors in a message</li>
							<li>End to end encrypt messages</li>
							<li>Send messages over UHF and VHF</li>
						</ul>
					</div>
					<img
						className={s.rightimage}
						src={require("../images/radiowave.jpg")}
						alt="Radiowave"
					/>
				</div>

				<div className={p.phone ? s.rightPhonecontainer : s.container}>
					<img
						className={s.image}
						src={require("../images/radiowave2.jpg")}
						alt="Radiowave"
					/>
					<div className={p.phone ? s.text : s.righttext}>
						<div className={s.title}>Get Radiowave</div>
						<p>
							The app is only available on android and can be
							downloaded for free from{" "}
							<a
								href="https://drive.usercontent.google.com/download?id=1-3tTwIW8VsCGvEYr8P3rsMZLJbbDivWA&export=download"
								target="_blank"
							>
								here
							</a>
							.<br />
							<br />
							ZL3LEO has put a lot of work into the app so if you
							would like to contribute, please consider donating
							with paypal to: brucew@snap.net.nz
						</p>
					</div>
				</div>

				<div style={{ marginTop: "32px" }}>
					<div className={s.text}>
						<div className={s.title}>How the app works</div>
						<p>
							Messages are encoded into audio which is transmitted
							over radio. When another phone receives that audio,
							the phone decodes it and if the message was decoded
							successfully, a confirmation message is sent.
						</p>
					</div>
				</div>

				<div class={s.videocontainer}>
					<iframe
						src="https://www.youtube.com/embed/SXIQKZuqMJo"
						title="Radiowave App"
						frameborder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
						referrerpolicy="strict-origin-when-cross-origin"
						allowfullscreen="true"
					></iframe>
				</div>

				<div style={{ marginTop: "32px" }}>
					<div className={s.text}>
						<div className={s.title}>Distance</div>
						<p>
							The app has sent messages over 12 km by ZL3LEO in a
							car with a handheld on 5 watts.
						</p>
					</div>
				</div>

				<div style={{ marginTop: "32px" }}>
					<div className={s.text}>
						<div className={s.title}>About</div>
						<p>
							The app has been designed by ZL3LEO. If you find any
							problems with the app, please contact:
							leoward1.nz@gmail.com
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default MainPage;
