import s from "./MainPage.module.css";
import s2 from "./Help.module.css";

function Help(p) {
	return (
		<div className={s2.about}>
			<div className={p.phone ? s.phonecolumn : s.column}>
				<div className={s2.title}>What do the icons mean?</div>

				<div className={p.phone ? s.phonecontainer : s2.container}>
					<img
						className={s2.iconImage}
						src={require("../images/sent_failed.jpg")}
						alt="Radiowave"
					/>

					<div className={p.phone ? s.phonetext : s.righttext}>
						A wifi icon means the message was sent. If the icon
						doesn't change, it means the message was received with
						errors or not received.
					</div>
				</div>

				<div className={p.phone ? s.phonecontainer : s2.container}>
					<img
						className={s2.iconImage}
						src={require("../images/sent_success.jpg")}
						alt="Radiowave"
					/>
					<div className={p.phone ? s.phonetext : s.righttext}>
						A tick icon on your message means the message was
						received and decoded with no errors.
					</div>
				</div>

				<div className={p.phone ? s.phonecontainer : s2.container}>
					<img
						className={s2.iconImage}
						src={require("../images/sent_encrypted.jpg")}
						alt="Radiowave"
					/>
					<div className={p.phone ? s.phonetext : s.righttext}>
						A lock icon on your message means the encrypted message
						was received and decrypted with no errors.
					</div>
				</div>

				<div className={p.phone ? s.phonecontainer : s2.container}>
					<img
						className={s2.iconImage}
						src={require("../images/received_success.jpg")}
						alt="Radiowave"
					/>
					<div className={p.phone ? s.phonetext : s.righttext}>
						A tick on a received message means the message was
						received with no errors.
					</div>
				</div>

				<div className={p.phone ? s.phonecontainer : s2.container}>
					<img
						className={s2.iconImage}
						src={require("../images/received_encrypted.jpg")}
						alt="Radiowave"
					/>
					<div className={p.phone ? s.phonetext : s.righttext}>
						A lock on a received message means the encrypted message
						was decrypted with no errors.
					</div>
				</div>

				<div className={p.phone ? s.phonecontainer : s2.container}>
					<img
						className={s2.iconImage}
						src={require("../images/received_failed.jpg")}
						alt="Radiowave"
					/>
					<div className={p.phone ? s.phonetext : s.righttext}>
						An exclamation mark icon on a received message means the
						message contains errors or failed to decrypt.
					</div>
				</div>

				<div className={p.phone ? s.phonecontainer : s2.container}>
					<img
						className={s2.iconImage}
						src={require("../images/received_correction.jpg")}
						alt="Radiowave"
					/>
					<div className={p.phone ? s.phonetext : s.righttext}>
						The number next to the tick is how many bits were
						corrected. 8 bits were corrected in this message. If
						there are no numbers next to a tick or a lock, it means
						all the bits were correct.
					</div>
				</div>

				<div style={{ marginTop: "32px" }}></div>

				<div className={s2.title}>Commands</div>
				<div className={s2.container}>
					<div className={s2.command}>/hi</div>

					<div className={s.righttext}>
						This command sends "Hello (username)!".
					</div>
				</div>
				<div className={s2.container}>
					<div className={s2.command}>/time</div>

					<div className={s.righttext}>
						This command sends "The time and date is (time and
						date)".
					</div>
				</div>
				<div className={s2.container}>
					<div className={s2.command}>/gps message</div>

					<div className={s.righttext}>
						This command sends "(message) (link to google maps of
						the gps location from the phone)".
					</div>
				</div>

				<div style={{ marginTop: "32px" }}></div>

				<div className={s2.title}>Resend, delete, edit and copy</div>
				<div className={s.text}>
					Hold down a message to resend, delete or edit it. Double tap
					a message to copy it.
				</div>

				<div style={{ marginTop: "32px" }}></div>

				<div className={s2.title}>What is raw data?</div>
				<div className={s.text}>
					The raw data page shows all messages you have received,
					including messages that have errors and sent between other
					people.
				</div>

				<div className={p.phone ? s.phonecontainer : s.container}>
					<div className={s.text}>
						<div className={s.title}>Microphone volume</div>
						<p>
							The light blue line shows the micrphone volume and
							it should be about half way when receiving. This can
							be adjusted by changing the volume on the radio.
							Messages can not be sent while receiving to prevent
							more than one person transmitting at the same time.
						</p>
					</div>
					<img
						className={s.rightimage}
						src={require("../images/microphone_volume.jpg")}
						alt="Radiowave"
						style={{ marginBottom: "32px" }}
					/>
				</div>

				<div className={p.phone ? s.rightPhonecontainer : s.container}>
					<img
						className={s.image}
						src={require("../images/bitclocklength.jpg")}
						alt="Radiowave"
						style={{ marginBottom: "32px" }}
					/>
					<div className={p.phone ? s.text : s.righttext}>
						<div className={s.title}>Bit clock length</div>
						<p>
							The audio at the start of the message is used to
							align the bits. The time it plays for can be changed
							here. Some radios have a delay before it starts
							transmitting so setting this to medium could make
							sending messages more reliable.
						</p>
					</div>
				</div>

				<div className={p.phone ? s.phonecontainer : s.container}>
					<div className={s.text}>
						<div className={s.title}>Squelch</div>
						<p>
							This is the level of the receiving squelch. If your
							phone keeps saying receiving, increasing this will
							fix it.
						</p>
					</div>
					<img
						className={s.rightimage}
						src={require("../images/squelch.jpg")}
						alt="Radiowave"
					/>
				</div>

				<div className={p.phone ? s.rightPhonecontainer : s.container}>
					<img
						className={s.image}
						src={require("../images/cable.jpg")}
						alt="Radiowave"
					/>
					<div className={p.phone ? s.text : s.righttext}>
						<div className={s.title}>How to use the app</div>
						<p>
							A cable can be used to more reliably transmit the
							audio from the phone. This is the{" "}
							<a href="https://www.alafone.com/product/aprs-k1-cable/">
								APRS-K1 cable
							</a>
							. Your radio must have VOX on. Make sure the volume
							in the radio and phone is turned up.
						</p>
					</div>
				</div>

				<br />
			</div>
		</div>
	);
}

export default Help;
